import React from 'react'
import Layout from '../components/layout/layout'
import SEOData from '../components/seo'

const IndexPage: React.FunctionComponent = () => (
  <Layout>
    <SEOData title="London-based Treasury Consultancy" />
    <p>This website is under construction. Thank you for your interest.</p>
    <p>
      Please contact{' '}
      <a href="mailto:mark@mstreasury.com">mark@mstreasury.com</a> for enquiries
      about our Treasury Consultancy services.
    </p>
  </Layout>
)

export default IndexPage
